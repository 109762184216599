@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

body.dark {
  background-color: #09090b;
}

body {
  --track-bg-color: #e9e8e9;
  --track-0-bg-color: #e9e8e9;
  --thumb-bg-color: white;
  --thumb-border-color: #09090b;
}

body.dark {
  --track-bg-color: rgba(255, 255, 255, 0.15);
  --track-0-bg-color: rgba(255, 255, 255, 0.15);
  --thumb-bg-color: #09090b;
  --thumb-border-color: white;
}

.customSlider {
  max-width: 182px;
  width: 182px;
}

.customSlider-track {
  border-radius: 30px;
  background: var(--track-bg-color);
  height: 22px;
}

.customSlider-track.customSlider-track-0 {
  background-color: var(--track-0-bg-color);
}

.customSlider-thumb {
  background-color: var(--thumb-bg-color);
  border-radius: 100%;
  cursor: pointer;
  height: 22px;
  outline: none;
  position: relative;
  top: 0px;
  width: 22px;

  &:before {
    content: '';
    border-radius: 100%;
    border: 4px solid var(--thumb-border-color);
    height: 100%;
    left: 0;
    position: absolute;
    transition: border-width 200ms;
    top: 0;
    width: 100%;
  }

  &:active:before {
    border-width: 8px;
  }
}

.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--main-color-hover);
}

.fittedGridControls {
  opacity: 0;
}

.fittedGridItem.nested:hover .fittedGridControls {
  opacity: 1;
}

.fittedGridItem.top:hover .fittedGridControls.top {
  opacity: 1;
}

.rect {
}

.splash-svg {
  transition: 500ms all;
  transform: translateY(0);
  filter: drop-shadow(0 15px 40px rgba(0, 0, 0, 0.04));

  &:hover {
    transform: translateY(-8px);
  }

  .rect {
    transition: 500ms all;
  }

  &:hover {
    .rect {
      fill: #343434;
    }
  }
}

.controls {
  opacity: 0;
}

.controls-activator.nested:hover .controls.nested {
  opacity: 1;
}

.controls-activator:hover .controls {
  opacity: 1;
}

.swichSvgOnHover {
  min-height: 22px;
  min-width: 22px;

  & > *:nth-child(2) {
    display: none;
  }

  &:hover {
    > *:nth-child(1) {
      display: none;
    }

    > *:nth-child(2) {
      display: block;
    }
  }
}

.rotateOnHover {
  &:hover {
    transform: rotate(90deg);
  }
}

.animation-1-bg {
  background-image: url(../public/animation-1-bg-m.jpg);
  background-size: 660px auto;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen(lg) {
  .animation-1-bg {
    background-image: none;
  }
}
